<template>
  <v-row>
    <v-col cols="12" class="py-0">
      <div class="title-12">{{todayDate}}</div>
    </v-col>
    <v-col cols="12">
      <v-btn
        rounded
        depressed
        color="#F2F2F2"
        class="our-cooks-btn title-12"
        height="40"
        @click="$emit('open-chef-list')"
        :class="{'active-btn': showChefList}"
      >
        Our chefs 
        <span v-if="!isSelectedChefsEmpty">
          ({{selectedChefs.length}})
        </span>
      </v-btn>
      <v-btn
        rounded
        depressed
        color="#F2F2F2"
        height="40"
        class="ml-3 our-cooks-btn title-12"
        @click="$emit('open-filters-overlay')"
        :class="{'active-btn': showSelectFilters}"
      >
        <v-icon size="19" class="mr-2">
          {{filter_icon}}
        </v-icon>
        I am looking for ...
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
  import { mapGetters } from 'vuex';
  import moment from 'moment';

  export default {
    name: 'OurCooksFiltersBar',
    props: ["showChefList", "showSelectFilters"],
    computed: {
      ...mapGetters(["selectedChefs"]),
      isSelectedChefsEmpty() {
        return this.selectedChefs.length === 0;
      }
    },
    data: () => ({
      filter_icon: "$vuetify.icons.filter_icon",
      todayDate: moment().format("dddd D MMMM")
    })
  };
</script>

<style type="text/css">
  #my-app .our-cooks-btn {
    color: #001254;
  }

  #my-app .active-btn {
    background-color: #001254 !important;
    color: #ffffff;
  }

  #my-app .active-btn span {
    color: #ffffff;
  }

  #my-app .active-btn svg path {
    fill: #ffffff;
  }
</style>