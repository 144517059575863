<template>
  <div id="select-later-time" class="full-height">
    <v-row v-show="!hideBackBtn">
      <v-col cols="12" class="text-left">
        <v-btn
          icon
          depressed
          width="20"
          @click="goBackAction"
        >
          <v-icon size="20">{{back_btn}}</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" class="text-left">
        <div class="title-12">Schedule {{schedule_title[orderReceiveMethod]}}</div>
      </v-col>
    </v-row>

    <div class="later-time-dates-container">
      <v-slide-group
        mandatory
        v-model="selected_later_date"
        @change="resetLaterTime"
      >
        <v-slide-item
          v-for="(item, index) in laterDates"
          :key="'date-'+index"
          v-slot="{ active, toggle }"
          :value="item.id"
        >
          <v-btn
            depressed
            :input-value="active"
            width="148"
            height="79"
            class="mx-2 date-btn"
            active-class="date-btn-active"
            :disabled="item.disabled"
            @click="toggle"
          >
            <div class="text-left">
              <div class="title-14">{{item.title}}</div>
              <div class="small-text">{{item.formattedDate}}</div>
            </div>
          </v-btn>
        </v-slide-item>
      </v-slide-group>
    </div>

    <div id="later-time-list" class="overflow-content">
      <div class="title-6" v-if="time_list.length == 0">
        Λυπούμαστε αλλά είμαστε κλειστά! <br>
        Το μενού ανανεώνεται καθημερινά στις 10:30.<br>
        Το Wecook δέχεται παραγγελίες
        <br> Δευτέρα ως Παρασκευή 10:30-19:45. <br>
        Ευχαριστούμε
      </div>

      <v-list v-else>
        <v-list-item-group v-model="selected_later_time" mandatory>
          <v-list-item
            v-for="(item, index) in time_list"
            class="my-list-item"
            :key="'time-'+index"
            :id="'time-'+index"
          >
            <template v-slot:default="{ active }">
              <v-list-item-content>
                <v-list-item-title v-text="item" class="title-14 text-left"></v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-checkbox
                  :input-value="active"
                  :off-icon="radio_btn_off"
                  :on-icon="radio_btn_on"
                  :ripple="false"
                  readonly
                  class="my-checkbox"
                ></v-checkbox>
              </v-list-item-action>
            </template>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </div>
    
    <div class="confirm-btn-container">
      <v-btn
        depressed
        class="order-now-btn"
        @click="goBackAction"
      >
        Confirm
      </v-btn>
    </div>
  </div>
</template>
  
<script>
  import { mapGetters, mapActions } from "vuex";

  export default {
    name: 'SelectLaterTime',
    props: {
      hideBackBtn: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      ...mapGetters([
        "orderReceiveMethod",
        "selectedLaterDate",
        "deliveryTimeList",
        "pickupTimeList",
        "laterDates",
        "end_time"
      ]),
      time_list() {
        let result = [];
        if (this.orderReceiveMethod == 'delivery') {
          result = this.deliveryTimeList;
        } else {
          result = this.pickupTimeList;
        }
        return result;
      },
      selected_later_date: {
        get() {
          let result = '';
          if (this.$store.state.orders.selectedLaterDate?.id != undefined) {
            result = this.$store.state.orders.selectedLaterDate.id
          }
          return result;
        },
        set(value) {
          let dateObj = this.laterDates.find(el => el.id == value);
          this.$store.dispatch('setSelectedLaterDate', dateObj).then(() => {
            this.setDeliveryTimeList();
            this.setPickupTimeList();
          });
        }
      },
      selected_later_time: {
        get() {
          let selectedLaterTime = 0;
          setTimeout(() => {
            if (this.orderReceiveMethod == 'delivery') {
              selectedLaterTime = this.$store.state.orders.selectedDeliveryLaterTime;
            } else {
              selectedLaterTime = this.$store.state.orders.selectedPickupLaterTime;
            }
            let timeId = 'time-'+selectedLaterTime;
            let timeElement = document.getElementById(timeId);
            if (timeElement != null) {
              timeElement.scrollIntoView({behavior: "smooth"});
            }
          }, 500);

          return selectedLaterTime;
        },
        set(value) {
          if (this.orderReceiveMethod == 'delivery') {
            this.$store.commit('setSelectedDeliveryLaterTime', value);
          } else {
            this.$store.commit('setSelectedPickupLaterTime', value);
          }
        }
      }
    },
    data: () => ({
      activeComponent: 'delivery',
      back_btn: "$vuetify.icons.back_btn",
      radio_btn_off: "$vuetify.icons.radio_btn_off",
      radio_btn_on: "$vuetify.icons.radio_btn_on",
      schedule_title: {
        delivery: 'delivery',
        pickup: 'pick-up'
      },
      scrollOptions: {
        duration: 300,
        offset: 0,
        easing: 'easeInOutCubic',
      }
    }),
    mounted() {
      let addressBottomSheet = document.getElementsByClassName('address-bottom-sheet')[0];
      addressBottomSheet.style.height = '100%';
    },
    methods: {
      ...mapActions([
        "setDeliveryTimeList",
        "setPickupTimeList",
        "setSelectedLaterDate",
        "setSelectedDeliveryLaterTime",
        "setSelectedPickupLaterTime"
      ]),
      goBackAction() {
        let addressBottomSheet = document.getElementsByClassName('address-bottom-sheet')[0];
        addressBottomSheet.style.height = 'initial';
        this.$emit('back-to-menu');
      },
      resetLaterTime() {
        if (this.orderReceiveMethod == 'delivery') {
          this.setSelectedDeliveryLaterTime(0);
        } else {
          this.setSelectedPickupLaterTime(0);
        }
      }
    }
  };
</script>
  
<style type="text/css">
  #later-time-list.overflow-content {
    margin: 0 -20px;
    padding: 0 20px 200px;
    height: 80%;
  }

  .later-time-dates-container {
    padding: 20px 0;
  }

  #later-time-list .v-list-item__content {
    padding: 22px 0;
  }

  #my-app .date-btn {
    background: #ffffff;
    border: 1px solid rgba(0, 18, 84, 0.5);
    border-radius: 8px;
    justify-content: flex-start;
    color: rgba(0, 18, 84, 0.5);
  }

  #my-app .date-btn div {
    color: rgba(0, 18, 84, 0.5);
  }

  #my-app .date-btn-active {
    border: 2px solid #001254;
    color: #001254;
  }

  #my-app .date-btn-active div {
    color: #001254;
  }

  #my-app .date-btn.v-btn--disabled, 
  #my-app .date-btn.v-btn--disabled div {
    background-color: #ffffff !important;
    color: #e0e0e0 !important;
    border-color: rgba(0, 18, 84, 0.2);
  }
</style>