<template>
  <div class="top-bar">
    <v-container>
      <v-row>
        <v-col cols="10" v-if="isClosed" class="pl-0">
          <div>Currently closed! We are open Mon - Fri</div>
          <div>{{working_hours}}</div>
        </v-col>
        <v-col cols="10" v-else class="pl-0">
          <v-btn
            depressed
            text
            class="address-btn"
            @click="$emit('show-select-address')"
          >
            <div>
              <div class="small-text text-left text-capitalize">
                {{orderReceiveMethod}}
              </div>
              <v-icon
                size="9"
                class="nav-icon"
              >
                {{nav_icon}}
              </v-icon>
              <span class="title-14" v-if="showDeliveryAddress && !isSelectedAddresEmpty">
                {{selectedAddress.name}}
              </span>
              <span class="title-14" v-else-if="showPickupAddress">
                {{pickupAddress}}
              </span>
              <span class="title-14" v-else>Select Address</span>
              <v-icon
                size="13"
              >
                mdi-circle-small
              </v-icon>
              <span class="title-14" v-if="showLaterDetails">{{selectedTime}}</span>
              <span class="title-14" v-else>
                {{selectedOrderReceiveTime[orderReceiveMethod].small_title}}
              </span>
              <v-icon
                size="13"
                class="down-arrow"
              >
                {{down_arrow_icon}}
              </v-icon>
            </div>
          </v-btn>
        </v-col>

        <v-col cols="2" class="text-right pr-0">
          <v-btn
            icon
            class="menu-btn"
            @click="$emit('show-nav-drawer')"
          >
            <v-icon size="18">
              {{menu_icon}}
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import moment from 'moment';

  export default {
    name: 'TopBar',
    computed: {
      ...mapGetters([
        "confirmAddress",
        "selectedAddress",
        "orderReceiveMethod",
        "pickupAddress",
        "selectedOrderReceiveTime",
        "selectedLaterDate",
        "selectedDeliveryLaterTime",
        "selectedPickupLaterTime",
        "workingHours",
        "deliveryTimeList",
        "pickupTimeList",
        "isClosed"
      ]),
      showDeliveryAddress() {
        return this.confirmAddress && this.orderReceiveMethod == 'delivery';
      },
      showPickupAddress() {
        return this.orderReceiveMethod == 'pickup';
      },
      showLaterDetails() {
        return this.confirmAddress && this.selectedOrderReceiveTime[this.orderReceiveMethod].id == 2;
      },
      isSelectedAddresEmpty() {
        return this.selectedAddress == null;
      },
      selectedTime() {
        let result = 'Select Time';
        let timeList = this[this.orderReceiveMethod+'TimeList'];
        if (Object.keys(this.selectedLaterDate).length != 0 && timeList.length != 0) {
          let date = moment(this.selectedLaterDate.date).format("ddd");
          let selectedLaterTime = 0;
          if (this.orderReceiveMethod == 'delivery') {
            selectedLaterTime = this.$store.state.orders.selectedDeliveryLaterTime;
          } else {
            selectedLaterTime = this.$store.state.orders.selectedPickupLaterTime;
          }
          let time = '';
          if (timeList.length != 0 && selectedLaterTime < timeList.length) {
            time = timeList[selectedLaterTime].replace(/\s/g, '');
          }
          result = date + ' ' + time;
        }
        return result;
      },
      working_hours() {
        let result = '';
        let hoursObj = this.workingHours[0];
        if (hoursObj.timeRanges.length != 0) {
          let startHour = hoursObj.timeRanges[0].start.split(":")[0];
          let startMinutes = hoursObj.timeRanges[0].start.split(":")[1];
          let start = moment({hour: startHour, minutes: startMinutes}).add(1, 'h').add(30, 'm');

          let endHour = hoursObj.timeRanges[0].end.split(":")[0];
          let endMinutes = hoursObj.timeRanges[0].end.split(":")[1];
          let end = moment({hour: endHour, minutes: endMinutes})
          result = start.format("HH:mm") + ' - ' + end.format("HH:mm");
        }
        return result;
      }
    },
    data: () => ({
      menu_icon: "$vuetify.icons.menu_icon",
      nav_icon: "$vuetify.icons.nav_icon",
      down_arrow_icon: "$vuetify.icons.down_arrow_icon"
    })
  };
</script>

<style type="text/css">
  .top-bar {
    position: fixed;
    width: inherit;
    max-width: inherit;
    margin-top: -20px;
    margin-left: -20px;
    padding: 8px 20px 0;
    background: #ffffff;
    z-index: 100;
  }

  .nav-icon {
    margin-right: 4px;
  }

  .down-arrow {
    margin: 2px 0 0 10px;
  }

  #my-app .address-btn {
    padding: 0;
    align-items: flex-start;
  }

  #my-app .address-btn.v-btn::before,
  #my-app .address-btn.v-btn::after {
    display: none;
  }
</style>
