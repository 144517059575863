<template>
  <div id="select-address-menu">
    <div class="d-flex mt-4 mb-7">
      <v-btn
        depressed
        height="24"
        class="delivery-btn"
        :class="{'active-btn': orderReceiveMethod === 'delivery'}"
        @click="selectReceiveOrderMethod('delivery')"
      >
        Delivery
      </v-btn>
      <v-btn
        depressed
        height="24"
        class="pickup-btn"
        :class="{'active-btn': orderReceiveMethod === 'pickup'}"
        @click="selectReceiveOrderMethod('pickup')"
      >
        Pick Up
      </v-btn>
    </div>
    
    <component
      v-bind:is="orderReceiveMethod"
      @change-address="$emit('change-address')"
      @select-later-time="$emit('select-later-time')"
    ></component>

    <v-row>
      <v-col cols="12">
        <v-btn
          block
          depressed
          class="title-12 confirm-btn"
          :disabled="!isConfirmActive"
          @click="$emit('confirm-select-address')"
        >
          Confirm
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from "vuex";
  import moment from "moment";
  import DeliveryOptions from '@/components/SelectAddress/DeliveryOptions';
  import PickupOptions from '@/components/SelectAddress/PickupOptions';

  export default {
    name: 'SelectAddressMenu',
    components: {
      'delivery': DeliveryOptions,
      'pickup': PickupOptions
    },
    computed: {
      ...mapGetters([
        "selectedAddress",
        "orderReceiveMethod"
      ]),
      isAddressSelected() {
        return this.selectedAddress != undefined && this.selectedAddress != null;
      },
      isConfirmActive() {
        let result = true;
        if (this.orderReceiveMethod == 'delivery' && !this.isAddressSelected) {
          result = false;
        }
        return result;
      },
    },
    data: () => ({
      location_icon: "$vuetify.icons.location_icon",
      time_icon: "$vuetify.icons.time_icon"
    }),
    methods: {
      ...mapActions([
        "setOrderReceiveMethod",
        "setSelectedLaterDate",
        "setConfirmAddress"
      ]),
      selectReceiveOrderMethod(method) {
        this.setOrderReceiveMethod(method);
        this.setConfirmAddress(false);
      }
    }
  };
</script>

<style type="text/css">
  #select-address-menu .delivery-btn,
  #select-address-menu .pickup-btn {
    font-weight: 400;
    font-size: 12px;
    line-height: 13px;
    color: #001254;
    background-color: #F2F2F2;
    border-radius: 20px;
  }

  #select-address-menu .pickup-btn {
    margin-left: -10px;
    z-index: 0;
  }

  #select-address-menu .active-btn {
    background-color: #001254;
    color: #ffffff;
    z-index: 1;
  }
</style>