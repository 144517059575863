<template>
  <div id="review-third-step" class="overflow-content">
    <v-row class="mt-3 text-left">
      <v-col cols="12">
        <v-btn
          icon
          depressed
          width="13"
          height="7"
          @click="$emit('go-back-action', 1)"
        >
          <v-icon size="13">{{left_arrow_btn}}</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row class="mt-0">
      <v-col cols="12">
        <div class="title-15">
          Dish Review
          <span class="dish-review-count">
            1/12
          </span>
        </div>
      </v-col>
    </v-row>

    <div>
      <v-btn
        icon
        width="60"
        height="4"
        :class="{'mr-2': index != reviewSteps.length - 1}"
        v-for="(item, index) in reviewSteps"
        :key="item.name"
      >
        <v-icon
          size="60"
          v-if="item.active"
        >
          {{review_bar_active}}
        </v-icon>
        <v-icon
          size="60"
          v-else
        >
          {{review_bar_inactive}}
        </v-icon>
      </v-btn>
    </div>

    <v-row class="mt-5">
      <v-col cols="12" class="d-flex align-center">
        <v-avatar size="40">
          <img
            alt="user"
            src="/img/chef-avatar.svg"
          >
        </v-avatar>
        <div class="ml-2">
          <div class="title-8">ΔΗΜΗΤΡΗΣ ΠΑΠΑΠΑΝΑΓΙΩΤΟΥ</div>
        </div>
      </v-col>
    </v-row>

    <v-row class="dish-image-background">
      <v-col cols="12" class="pa-0">
        <v-img
          contain
          class="text-right"
          src="/img/dish_img.svg"
        >
          <v-btn
            icon
            class="favourite-btn"
            width="34"
            height="30"
          >
            <v-icon size="34">
              {{ heart_blue }}
            </v-icon>
          </v-btn>
        </v-img>
      </v-col>

      <v-col cols="12" class="pt-5">
        <div class="title-3 white--text">
          Was it tasty?
        </div>
      </v-col>
      <v-col v-if="!showMoreOptions" cols="12" class="d-flex justify-center">
        <div
          v-for="item in emoticonsList"
          :key="item.id"
          :class="{'mr-5': item.id != 5}"
        >
          <div class="mb-3">
            <v-img
              contain
              height="35"
              :src="'/img/' + item.image"
            ></v-img>
          </div>
          <div class="small-text white--text">{{item.caption}}</div>
        </div>
      </v-col>
    </v-row>

    <div class="submit-btn-confirm">
      <v-btn
        block
        depressed
        class="title-12 confirm-btn"
        @click="$emit('continue', 3)"
      >
        Continue
      </v-btn>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from "vuex";

  export default {
    name: 'ReviewThirdStep',
    computed: {
      ...mapGetters([
        "reviewSteps"
      ]),
    },
    data: () => ({
      heart_blue: "$vuetify.icons.heart_blue",
      left_arrow_btn: "$vuetify.icons.left_arrow_btn",
      review_bar_active: "$vuetify.icons.review_bar_active",
      review_bar_inactive: "$vuetify.icons.review_bar_inactive",
      showMoreOptions: false,
      emoticonsList: [
        {id: 1, image: 'bliah.svg', caption: 'bliah!'},
        {id: 2, image: 'hmm.svg', caption: 'hmm!'},
        {id: 3, image: 'so-so.svg', caption: 'so - so'},
        {id: 4, image: 'like-it.svg', caption: 'Like it!'},
        {id: 5, image: 'wowza.svg', caption: 'Wowza :)'},
      ]
    }),
    methods: {
      ...mapActions([
      ])
    }
  };
</script>

<style type="text/css">
  #review-third-step.overflow-content {
    padding: 0 20px 30px;
  }

  .dish-review-count {
    font-size: 6px;
    font-weight: normal;
    line-height: 120%;
  }

  .dish-image-background {
    margin: 0;
    background-color: #001254;
    border-radius: 30px;
    padding-bottom: 18px;
  }
</style>