<template>
  <v-bottom-sheet
    v-model="show"
    scrollable
    content-class="my-bottom-sheet review-bottom-sheet"
  >
    <v-sheet
      class="text-center"
      id="review-overlay-sheet"
    >

      <component
        v-bind:is="activeComponent"
       @continue="goToStep"
       @close-overlay="closeOverlay"
       @go-back-action="goToStep"
       @save-review="saveReview"
      ></component>

    </v-sheet>
  </v-bottom-sheet>
</template>

<script>
  import { mapGetters, mapActions } from "vuex";
  import ReviewFirstStep from "@/components/ReviewOverlay/ReviewFirstStep"
  import ReviewSecondStep from "@/components/ReviewOverlay/ReviewSecondStep"
  import ReviewThirdStep from "@/components/ReviewOverlay/ReviewThirdStep"
  import ReviewFourthStep from "@/components/ReviewOverlay/ReviewFourthStep"

  export default {
    name: 'ReviewOverlay',
    props: ["visible"],
    components: {
      'review-first-step': ReviewFirstStep,
      'review-second-step': ReviewSecondStep,
      'review-third-step': ReviewThirdStep,
      'review-fourth-step': ReviewFourthStep
    },
    computed: {
      ...mapGetters([
        "reviewSteps"
      ]),
      show: {
        get () {
          return this.visible
        },
        set (value) {
           if (!value) {
            this.$emit('close');
          }
        }
      },
      isFirstStepActive() {
        return this.activeComponent == 'review-first-step';
      },
      bottomSheetStyle() {
        let result = '';
        if (!this.isFirstStepActive) {
          result = 'height:90%';
        }
        return result;
      }
    },
    data: () => ({
      activeComponent: 'review-first-step'
    }),
    methods: {
      ...mapActions([
        "activateReviewStep"
      ]),
      closeOverlay() {
        this.$emit('close');
      },
      goToStep(stepIndex) {
        this.activateReviewStep(stepIndex);
        this.activeComponent = this.reviewSteps[stepIndex].component;

        let el = document.getElementsByClassName("review-bottom-sheet")[0];
        if (stepIndex != 0) {
          el.style.height = '90%';
        } else {
          el.style.height = 'auto';
        }
      },
      saveReview() {
        //add save action
        this.$emit('close');
      }
    }
  };
</script>
