<template>
  <div id="pickup-options">
    <v-list>
      <v-list-item-group v-model="selected_pickup_time" mandatory>
        <v-list-item
          v-for="(item, index) in pickupTimeMenu"
          :key="'time-'+index"
          :value="item"
          two-line
          @click="selectLaterTime(item)"
        >
          <template v-slot:default="{ active }">
            <v-list-item-icon class="mr-4">
              <v-icon size="25">
                {{getItemIcon(item.icon)}}
              </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title
                class="title-12 text-left"
              >
                <div v-if="item.id == 2 && showLaterDetails">
                  {{selectedLaterDate.title}} at {{pickupTimeList[selectedPickupLaterTime]}}
                </div>
                <div v-else>{{item.title}}</div>
              </v-list-item-title>
              <v-list-item-subtitle class="item-subtitle">
                {{item.subtitle}}
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action class="small-text">
              Change
            </v-list-item-action>
          </template>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";

  export default {
    name: 'PickupOptions',
    computed: {
      ...mapGetters([
        "pickupTimeMenu",
        "selectedOrderReceiveTime",
        "orderReceiveMethod",
        "selectedLaterDate",
        "selectedPickupLaterTime",
        "pickupTimeList"
      ]),
      selected_pickup_time: {
        get() {
          return this.$store.state.orders.selectedOrderReceiveTime['pickup'];
        },
        set(value) {
          let payload = {prop: 'pickup', value: value}
          this.$store.commit('setSelectedOrderReceiveTimeProp', payload);
        }
      },
      isSelectedLaterDateEmpty() {
        return Object.keys(this.selectedLaterDate).length === 0;
      },
      showLaterDetails() {
        let isLaterSelected = this.selectedOrderReceiveTime[this.orderReceiveMethod].id == 2;
        return isLaterSelected && !this.isSelectedLaterDateEmpty;
      }
    },
    data: () => ({
      radio_btn_off: "$vuetify.icons.radio_btn_off",
      radio_btn_on: "$vuetify.icons.radio_btn_on",
      now_icon: "$vuetify.icons.now_icon",
      later_icon: "$vuetify.icons.later_icon",
    }),
    methods: {
      getItemIcon(value) {
        return this[value];
      },
      selectLaterTime(item) {
        if (item.id == 2) {
          this.$emit('select-later-time');
        }
      }
    }
  };
</script>

<style type="text/css">
  .pickup-title {
    text-align: left;
    padding-left: 56px;
    cursor: pointer;
  }

  .active-time {
    background-color: rgba(91, 231, 137, 0.2);
  }

  #pickup-options .item-subtitle {
    text-align: left;
    font-weight: 300;
    font-size: 12px;
    line-height: 120%;
    color: #001254;
  }
</style>