<template>
  <div id="change-address" class="full-height">
    <v-row v-show="!hideChangeAddressBackBtn">
      <v-col cols="12" class="text-left">
        <v-btn
          icon
          depressed
          width="20"
          @click="goBackAction"
        >
          <v-icon size="20">{{back_btn}}</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="text-left">
        <div class="title-12">Select Address</div>
        <div class="mt-8 body-3">Your Locations</div>
      </v-col>
    </v-row>

    <div :class="[isAddressListLarge ? 'overflow-content' : 'content-padding']">
      <v-list>
        <v-list-item-group v-model="selected_address">
          <v-list-item
            v-for="(item, index) in addressList"
            class="my-list-item"
            :key="'address-'+index"
          >
            <template v-slot:default="{ active }">
              <v-list-item-icon class="mr-4">
                <v-checkbox
                  :input-value="active"
                  :off-icon="radio_btn_off"
                  :on-icon="radio_btn_on"
                  :ripple="false"
                  class="my-checkbox"
                ></v-checkbox>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title
                  two-line
                  class="title-12 text-left"
                >
                  {{item.name}}
                </v-list-item-title>

                <v-list-item-subtitle
                  class="body-3"
                >
                  {{item.street}} {{item.streetNumber}}, {{item.postCode}}
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action
                class="small-text"
                :class="{'disabled-action': !isUserLoggedIn}"
                @click.stop="openEditAddressForm(item)"
              >
                Edit
              </v-list-item-action>
              <v-list-item-action
                class="small-text"
                :class="{'disabled-action': !isUserLoggedIn}"
                @click.stop="deleteAddress(item.id)"
              >
                <v-icon size="14">{{trash_icon}}</v-icon>
              </v-list-item-action>
            </template>
          </v-list-item>
        </v-list-item-group>

        <v-list-item
          class="py-2"
          @click="$emit('add-new-address')"
        >
          <v-list-item-icon class="mr-4">
            <v-icon>
              {{add_btn}}
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title
              class="new-address-title"
            >
              Add new address
            </v-list-item-title>
          </v-list-item-content>

          <v-list-item-action class="small-text">
            Add
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </div>

    <div class="confirm-btn-container">
      <v-btn
        block
        depressed
        class="order-now-btn"
        :disabled="!isAddressSelected"
        @click="confirmAddress"
      >
        Confirm
      </v-btn>
    </div>

    <v-overlay :value="showLoader" z-index=12>
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from "vuex";
  import axios from 'axios';

  export default {
    name: 'ChangeAddress',
    props: {
      hideChangeAddressBackBtn: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      ...mapGetters([
        "addressList",
        "selectedAddress",
        "isUserLoggedIn",
        "userProfile",
        "addressLimitationsList",
        "pickupAddressCoordinates"
      ]),
      selected_address: {
        get() {
          let id = ''; 
          let index = -1;

          if (this.isAddressSelected) {
            id = this.selectedAddress.id;
            index = this.addressList.findIndex(object => {
              return object.id === id;
            });
          }
          
          return index;
        },
        set(value) {
          if (value != undefined) {
            let selectedAddress = this.addressList[value];
            this.$store.commit('setSelectedAddress', selectedAddress);
            this.$gmapApiPromiseLazy().then(() => {
              let originLat = this.pickupAddressCoordinates.lat;
              let originLng = this.pickupAddressCoordinates.lng;
              let origin = new google.maps.LatLng(originLat, originLng);

              let destinationLat = selectedAddress.latitude;
              let destinationLng = selectedAddress.longitude;
              let destination = new google.maps.LatLng(destinationLat, destinationLng);
              const distance = Math.round(google.maps.geometry.spherical.computeDistanceBetween(origin, destination));
              let addressLimit = null;
              if (distance <= 2000) { // id: under2
                addressLimit = this.addressLimitationsList[0];
              } else if (distance > 2000 && distance <= 3000) { // id: over2
                addressLimit = this.addressLimitationsList[1];
              } else if (distance > 3000 && distance <= 5000) { // id: over3
                addressLimit = this.addressLimitationsList[2];
              } else if (distance > 5000 && distance <= 8000) { // id: over5
                addressLimit = this.addressLimitationsList[3];
              } else if (distance > 8000 && distance <= 10000) { // id: over8
                addressLimit = this.addressLimitationsList[4];
              } else if (distance > 10000 && distance <= 25000) { // id: over10
                addressLimit = this.addressLimitationsList[5];
              } else if (distance > 25000) { //id: over25
                addressLimit = this.addressLimitationsList[6];
              }
              this.setAddressLimitation(addressLimit);
            });
          }
        }
      },
      isAddressSelected() {
        return this.selectedAddress != undefined && this.selectedAddress != null;
      },
      isAddressListLarge() {
        return this.addressList.length > 2;
      }
    },
    data: () => ({
      showLoader: false,
      homeAddress: true,
      back_btn: "$vuetify.icons.back_btn",
      radio_btn_off: "$vuetify.icons.radio_btn_off",
      radio_btn_on: "$vuetify.icons.radio_btn_on",
      add_btn: "$vuetify.icons.add_btn",
      trash_icon: "$vuetify.icons.trash_icon"
    }),
    mounted() {
      if (this.isAddressListLarge) {
        let addressBottomSheet = document.getElementsByClassName('address-bottom-sheet')[0];
        addressBottomSheet.style.height = '100%';
      }
    },
    methods: {
      ...mapActions([
        "toggleSelectedAddress",
        "getAddressList",
        "setAddressLimitation"
      ]),
      confirmAddress() {
        let addressBottomSheet = document.getElementsByClassName('address-bottom-sheet')[0];
        addressBottomSheet.style.height = 'initial';
        this.$emit('back-to-menu');
      },
      openEditAddressForm(item) {
        if (this.isUserLoggedIn) {
          this.$emit('open-edit-address-form', item);
        }
      },
      deleteAddress(addressId) {
        if (this.isUserLoggedIn) {
          this.showLoader = true;
          let email = this.userProfile.email;
          let url = `${process.env.VUE_APP_BASE_URL}/accounts/We%20Cook/customers/${email}/addresses/${addressId}`;
          axios.delete(url).then(res => {
            this.getAddressList(email).then(() => {
              this.showLoader = false;
            });
          }).catch(er => {
            console.log(er);
          });
        }
      },
      goBackAction() {
        let addressBottomSheet = document.getElementsByClassName('address-bottom-sheet')[0];
        addressBottomSheet.style.height = 'initial';
        this.$emit('back-to-menu');
      }
    }
  };
</script>

<style type="text/css">
  .content-padding {
    padding-bottom: 120px;
  }

  #change-address .overflow-content {
    margin: 0 -20px;
    padding: 0 0 280px;
  }
</style>