<template>
  <div id="edit-address">
    <v-row v-show="!hideEditAddressBackBtn">
      <v-col cols="12" class="text-left">
        <v-btn
          icon
          depressed
          width="20"
          @click="$emit('change-address')"
        >
          <v-icon size="20">{{back_btn}}</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="text-left">
        <div class="title-12">Edit address</div>
      </v-col>
    </v-row>

    <v-form class="mt-7">
      <gmap-autocomplete
        class="introInput"
        slot-ref-name="input"
        @place_changed="setPlace"
      >
        <template v-slot:default="slotProps">
          <v-text-field
            v-model="addressLine"
            label="Address Line"
            background-color="#F2F2F2"
            class="my-text-field autocomplete-input"
            color="#001254"
            solo
            flat
            rounded
            height="46"
            hide-details
            ref="input"
            v-on:listeners="slotProps.listeners"
            v-on:attrs="slotProps.attrs"
          ></v-text-field>
        </template>
      </gmap-autocomplete>

      <v-text-field
        v-model="address_floor"
        label="Floor*"
        background-color="#F2F2F2"
        class="my-text-field"
        color="#001254"
        solo
        flat
        rounded
        height="46"
        hide-details
      ></v-text-field>

      <v-text-field
        v-model="address_doorbell"
        label="Doorbell*"
        background-color="#F2F2F2"
        class="my-text-field"
        color="#001254"
        solo
        flat
        rounded
        height="46"
        hide-details
      ></v-text-field>

      <v-text-field
        v-model="address_comment"
        label="Comments"
        background-color="#F2F2F2"
        class="my-text-field"
        color="#001254"
        solo
        flat
        rounded
        height="46"
        hide-details
      ></v-text-field>

      <v-text-field
        v-model="address_name"
        maxlength="16"
        label="Pick a name to save this address* (ex. home)"
        background-color="#F2F2F2"
        class="my-text-field"
        color="#001254"
        solo
        flat
        rounded
        height="46"
        hide-details
      ></v-text-field>

      <v-row>
        <v-col cols="12">
          <v-btn
            block
            depressed
            class="title-12 confirm-btn"
            :disabled="!isValidForm"
            @click="editAddressAction"
          >
            Confirm
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';

  export default {
    name: 'EditAddress',
    props: {
      hideEditAddressBackBtn: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      ...mapGetters([
        "editAddress",
        "userProfile"
      ]),
      addressLine: {
        get() {
          let result = '';
          if (this.$store.state.profile.editAddress != null) {
            let street = this.$store.state.profile.editAddress.street;
            let streetNumber = this.$store.state.profile.editAddress.streetNumber;
            let postCode = this.$store.state.profile.editAddress.postCode;
            result = street + ' ' + streetNumber + ', ' + postCode;
          }
          return result;
        },
        set(value) {
          console.log(value);
        }
      },
      address_floor: {
        get() {
          return this.$store.state.profile.editAddress.floor;
        },
        set(value) {
          this.$store.commit('setEditAddressProp', {prop: 'floor', value: value});
        }
      },
      address_doorbell: {
        get() {
          return this.$store.state.profile.editAddress.doorbell;
        },
        set(value) {
          this.$store.commit('setEditAddressProp', {prop: 'doorbell', value: value});
        }
      },
      address_name: {
        get() {
          return this.$store.state.profile.editAddress.name;
        },
        set(value) {
          this.$store.commit('setEditAddressProp', {prop: 'name', value: value});
        }
      },
      address_comment: {
        get() {
          return this.$store.state.profile.editAddress.comment;
        },
        set(value) {
          this.$store.commit('setEditAddressProp', {prop: 'comment', value: value});
        }
      },
      isAddressLineEmpty() {
        return (this.addressLine === null || this.addressLine === '');
      },
      isAddressNameEmpty() {
        return this.editAddress.doorbell === '';
      },
      isStreetEmpty() {
        return this.editAddress.street === '';
      },
      isStreetNumberEmpty() {
        return this.editAddress.streetNumber === '';
      },
      isPostCodeEmpty() {
        return this.editAddress.postCode === '';
      },
      isValidForm() {
        return !this.isAddressNameEmpty && !this.isAddressLineEmpty;
      },
      isValidAddress() {
        return  !this.isStreetEmpty && !this.isStreetNumberEmpty && !this.isPostCodeEmpty;
      }
    },
    data: () => ({
      back_btn: "$vuetify.icons.back_btn",
      add_btn: "$vuetify.icons.add_btn",
      addressObj: null,
      showErrorMessage: false,
      errorMessage: '',
      addressExists: false
    }),
    methods: {
      ...mapActions(["setEditAddressProp"]),
      setPlace(place) {
        let lng = 0;
        let lat = 0;

        if (place.geometry.location != undefined) {
          lng = place.geometry.location.lng();
          lat = place.geometry.location.lat();
        }

        this.setEditAddressProp({
          prop: 'latitude',
          value: lat
        });

        this.setEditAddressProp({
          prop: 'longitude',
          value: lng
        });

        for (const component of place.address_components) {
          const componentType = component.types[0];

          switch (componentType) {
            case "street_number":
              let payload1 = {
                prop: "streetNumber",
                value: component.long_name
              }
              this.setEditAddressProp(payload1);
              break;

            case "route":
              let payload2 = {
                prop: "street",
                value: component.long_name
              }
              this.setEditAddressProp(payload2);
              break;

            case "postal_code":
              let payload3 = {
                prop: "postCode",
                value: parseInt(component.long_name.replace(" ", ""))
              }
              this.setEditAddressProp(payload3);
              break;
          }
        }
      },
      editAddressAction() {
        this.doGeocode(this.addressLine);
        if (this.isValidAddress) {
          this.$emit('edit-address', this.editAddress);
        } else {
          if (this.isStreetNameEmpty) {
            this.errorMessage = 'Please choose a street.';
          } else if (this.isStreetNumberEmpty) {
            this.errorMessage = 'Please choose a street number.';
          } else if (this.isPostalCodeEmpty) {
            this.errorMessage = 'Please choose a postal code.';
          }
          this.showErrorMessage = true;
        }
      },
      doGeocode(addr) {
        this.$gmapApiPromiseLazy().then(() => {
          const geocoder = new google.maps.Geocoder();

          geocoder.geocode(
            {
              address: addr
            }, function(results, status) {
              if (status === google.maps.GeocoderStatus.OK && results.length > 0) {
                this.addressExists = true;
              } else {
                this.addressExists = false;
              }
            }.bind(this)
          )
        });
      }
    }
  };
</script>
