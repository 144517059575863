<template>
  <div id="new-address-menu">
    <v-row v-show="!hideNewAddressBackBtn">
      <v-col cols="12" class="text-left">
        <v-btn
          icon
          depressed
          width="20"
          @click="$emit('change-address')"
        >
          <v-icon size="20">{{back_btn}}</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="text-left">
        <div class="title-12">Add new address</div>
      </v-col>
      <v-col cols="12" v-if="showErrorMessage">
        <div class="error-message">{{errorMessage}}</div>
      </v-col>
    </v-row>

    <v-form class="mt-7">
      <gmap-autocomplete
        class="introInput"
        slot-ref-name="input"
        :componentRestrictions="{ country: 'gr'}"
        :options="gmapOptions"
        @place_changed="setPlace"
      >
        <template v-slot:default="slotProps">
          <v-text-field
            v-model="addressLine"
            label="Address Line*"
            background-color="#F2F2F2"
            class="my-text-field autocomplete-input"
            color="#001254"
            solo
            flat
            rounded
            height="46"
            hide-details
            ref="input"
            v-on:listeners="slotProps.listeners"
            v-on:attrs="slotProps.attrs"
          ></v-text-field>
        </template>
      </gmap-autocomplete>

      <v-text-field
        v-model="floor"
        label="Floor*"
        background-color="#F2F2F2"
        class="my-text-field"
        color="#001254"
        solo
        flat
        rounded
        height="46"
        hide-details
      ></v-text-field>

      <v-text-field
        v-model="doorbell"
        label="Doorbell*"
        background-color="#F2F2F2"
        class="my-text-field"
        color="#001254"
        solo
        flat
        rounded
        height="46"
        hide-details
      ></v-text-field>

      <v-text-field
        v-model="comment"
        label="Comments"
        background-color="#F2F2F2"
        class="my-text-field"
        color="#001254"
        solo
        flat
        rounded
        height="46"
        hide-details
      ></v-text-field>

      <v-text-field
        v-model="addressName"
        maxlength="16"
        label="Pick a name to save this address* (ex. home)"
        background-color="#F2F2F2"
        class="my-text-field"
        color="#001254"
        solo
        flat
        rounded
        height="46"
        hide-details
      ></v-text-field>

      <v-row>
        <v-col cols="12">
          <v-btn
            block
            depressed
            class="title-12 confirm-btn"
            :disabled="!isValidForm"
            @click="addNewAddress"
          >
            Confirm
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

  export default {
    name: 'AddNewAddress',
    props: {
      hideNewAddressBackBtn: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      ...mapGetters(["userProfile"]),
      isFormattedAddressEmpty() {
        return (this.formattedAddress === null || this.formattedAddress === '');
      },
      isAddressNameEmpty() {
        return this.addressName === '';
      },
      isAddressLineEmpty() {
        return this.addressLine === '';
      },
      isStreetNameEmpty() {
        return this.streetName === '';
      },
      isStreetNumberEmpty() {
        return this.streetNumber === '';
      },
      isPostalCodeEmpty() {
        return this.postalCode === '';
      },
      isFloorEmpty() {
        return this.floor === '';
      },
      isDoorbellEmpty() {
        return this.doorbell === '';
      },
      isValidForm() {
        return !this.isAddressNameEmpty && !this.isAddressLineEmpty && !this.isFloorEmpty
          && !this.isDoorbellEmpty;
      },
      isValidAddress() {
        return  !this.isStreetNameEmpty && !this.isStreetNumberEmpty && !this.isPostalCodeEmpty;
      }
    },
    data: () => ({
      back_btn: "$vuetify.icons.back_btn",
      add_btn: "$vuetify.icons.add_btn",
      gmapOptions: {
        fields: ['place_id', 'address_components', 'geometry', 'formatted_address', 'name']
      },
      addressLine: '',
      showErrorMessage: false,
      errorMessage: '',
      addressObj: null,
      comment: '',
      floor: '',
      doorbell: '',
      addressName: '',
      formattedAddress: '',
      addressExists: false,
      streetNumber: '',
      streetName: '',
      postCode: '',
      city: {
        id: 1,
        content: {
          el: {
            id: 2,
            title: "Αθήνα"
          },
          en: {
            id: 1,
            title: "Athens"
          }
        }
      }
    }),
    methods: {
      ...mapActions(["setNewAddress"]),
      setPlace(place) {
        this.formattedAddress = place.formatted_address;

        this.setNewAddress(place);

        let lng = 0;
        let lat = 0;

        if (place.geometry.location != undefined) {
          lng = place.geometry.location.lng();
          lat = place.geometry.location.lat();
        }

        for (const component of place.address_components) {
          const componentType = component.types[0];

          switch (componentType) {
            case "street_number": 
              this.streetNumber = component.long_name;
              break;

            case "route":
              this.streetName = component.long_name;
              break;

            case "postal_code":
              this.postCode = parseInt(component.long_name.replace(" ", ""));
              break;
          }
        }

        this.addressObj = {
          street: this.streetName,
          streetNumber: this.streetNumber,
          city: this.city,
          postCode: this.postCode,
          latitude: lat,
          longitude: lng,
          phoneNumber: this.userProfile.phoneNumber,
          floor: this.floor,
          comment: this.comment,
          defaultAddress: true,
          doorbell: this.doorbell,
          name: this.addressName
        };
      },
      addNewAddress() {
        this.doGeocode(this.formattedAddress);
        if (this.isValidAddress) {
          this.addressObj.floor = this.floor;
          this.addressObj.comment = this.comment;
          this.addressObj.doorbell = this.doorbell;
          this.addressObj.name = this.addressName;
          this.$emit('create-address', this.addressObj);
        } else {
          if (this.isStreetNameEmpty) {
            this.errorMessage = 'Please choose a street.';
          } else if (this.isStreetNumberEmpty) {
            this.errorMessage = 'Please choose a street number.';
          } else if (this.isPostalCodeEmpty) {
            this.errorMessage = 'Please choose a postal code.';
          }
          this.showErrorMessage = true;
        }
      },
      doGeocode(addr) {
        this.$gmapApiPromiseLazy().then(() => {
          const geocoder = new google.maps.Geocoder();

          geocoder.geocode(
            {
              address: addr
            }, function(results, status) {
              if (status === google.maps.GeocoderStatus.OK && results.length > 0) {
                this.addressExists = true;
              } else {
                this.addressExists = false;
              }
            }.bind(this)
          )
        });
      }
    }
  };
</script>
