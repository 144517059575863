<template>
  <div id="review-fourth-step" class="overflow-content">
    <v-row class="mt-3 text-left">
      <v-col cols="12">
        <v-btn
          icon
          depressed
          width="13"
          height="7"
          @click="$emit('go-back-action', 2)"
        >
          <v-icon size="13">{{left_arrow_btn}}</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row class="mt-0">
      <v-col cols="12">
        <div class="title-15">
          Final thoughts
        </div>
      </v-col>
    </v-row>

    <div>
      <v-btn
        icon
        width="60"
        height="4"
        :class="{'mr-2': index != reviewSteps.length - 1}"
        v-for="(item, index) in reviewSteps"
        :key="item.name"
      >
        <v-icon
          size="60"
          v-if="item.active"
        >
          {{review_bar_active}}
        </v-icon>
        <v-icon
          size="60"
          v-else
        >
          {{review_bar_inactive}}
        </v-icon>
      </v-btn>
    </div>

    <v-row class="mt-0">
      <v-col cols="12" class="pb-2">
        <div class="title-5">
          Write a note;)
        </div>
      </v-col>
      <v-col cols="12" class="pt-0">
        <div class="body-3 text-center">
          Anything you want to share with us?
        </div>
      </v-col>
      <v-col cols="12" class="pt-4">
        <v-textarea
          filled
          no-resize
          rows="8"
          hide-details
          placeholder="Write a note"
          class="my-textarea"
        ></v-textarea>
      </v-col>
    </v-row>

    <v-row class="mt-1">
      <v-col cols="12">
        <div class="body-3">
          Who do you want to share this note with?
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-chip-group
          column
          multiple
          id="share-items-group"
        >
          <v-chip
            v-for="(item, index) in items"
            :key="'item-'+index"
            class="body-3 share-item"
            active-class="active-item"
          >
            {{ item.name }}
            <v-icon size="8" class="ml-4">{{plus_btn}}</v-icon>
          </v-chip>
        </v-chip-group>
      </v-col>
    </v-row>

    <div class="submit-btn-container">
      <v-btn
        block
        depressed
        class="title-12 confirm-btn"
        @click="$emit('save-review')"
      >
        Continue
      </v-btn>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from "vuex";

  export default {
    name: 'ReviewFourthStep',
    computed: {
      ...mapGetters([
        "reviewSteps"
      ]),
    },
    data: () => ({
      heart_blue: "$vuetify.icons.heart_blue",
      left_arrow_btn: "$vuetify.icons.left_arrow_btn",
      review_bar_active: "$vuetify.icons.review_bar_active",
      review_bar_inactive: "$vuetify.icons.review_bar_inactive",
      plus_btn: "$vuetify.icons.plus_btn",
      showMoreOptions: false,
      emoticonsList: [
        {id: 1, image: 'bliah.svg', caption: 'bliah!'},
        {id: 2, image: 'hmm.svg', caption: 'hmm!'},
        {id: 3, image: 'so-so.svg', caption: 'so - so'},
        {id: 4, image: 'like-it.svg', caption: 'Like it!'},
        {id: 5, image: 'wowza.svg', caption: 'Wowza :)'},
      ],
      items: [
        {name: 'WeCook', active: false},
        {name: 'Chef Name', active: false},
        {name: 'Chef', active: false}
      ]
    }),
    methods: {
      ...mapActions([
      ])
    }
  };
</script>

<style type="text/css">
  #review-fourth-step.overflow-content {
    padding: 0 20px 30px;
  }

  #share-items-group .v-slide-group__content {
    justify-content: center;
  }

  #share-items-group .share-item {
    background: #E0E0E0;
    border-radius: 16px;
    color: #001254;
    padding: 8px 12px;
  }

  #share-items-group .active-item {
    background: #001254;
  }

  #share-items-group .v-chip.active-item .v-chip__content {
    color: #5BE789;
  }

  #share-items-group .v-chip.active-item .v-chip__content svg path {
    fill: #5BE789;
  }
</style>