<template>
  <v-col cols="12" sm="6" lg="4" xl="3" class="dish-container">
    <v-row>
      <v-col
        cols="12"
        class="d-flex align-center my-link"
        @click="$emit('go-to-chef-profile', dishItem.chefId)"
      >
        <v-avatar size="40">
          <img
            alt="user"
            :src="publicPath+dishItem.chefAvatar"
          >
        </v-avatar>
        <div class="ml-2">
          <div class="title-8">{{dishItem.chefName}}</div>
          <!-- Reviews to be added to another version -->
          <!-- <div class="chef-review-container">
            <div>
              <v-icon
                size="12"
                class="mr-1"
              >
                {{star_icon}}
              </v-icon>
            </div>
            <div class="small-text">{{dishItem.chefReview}}</div>
          </div> -->
        </div>
      </v-col>
      <v-col cols="12" class="dish-img-container">
        <v-img
          :aspect-ratio="4/3"
          class="dish-img"
          :src="dishItem.dishImage == '' ? '/img/dish_thumbnail.png' : publicPath+dishItem.dishImage"
          lazy-src="/img/dish_thumbnail.png"
          @click="addToCart"
        >
          <template v-slot:placeholder>
            <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
            >
              <v-progress-circular
                indeterminate
                color="#001254"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
        <v-btn
          v-if="dishIndex == 0"
          depressed
          height="34"
          class="favourite-btn-large"
          @click.stop="$emit('toggleFavourite', dishItem)"
        >
          Add to favourites
          <v-spacer></v-spacer>
          <v-icon class="ml-2">
            {{ dishItem.favourite ? heart_blue : heart_white }}
          </v-icon>
        </v-btn>

        <v-btn
          v-else
          icon
          class="favourite-btn"
          width="44"
          height="34"
          @click.stop="$emit('toggleFavourite', dishItem)"
        >
          <v-icon>
            {{ dishItem.favourite ? favourite_btn_enabled : favourite_btn_disabled }}
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="9">
        <v-row>
          <v-col cols="8" class="pb-0">
            <div class="title-10">{{dishItem.title}}</div>
          </v-col>
          <v-col cols="4" class="pb-0 text-right">
            <div class="title-13">{{dishItem.price.toFixed(2)}} &euro;</div>
          </v-col>

          <v-col cols="12" class="py-2">
            <div class="body-3 max-three-lines">{{dishItem.description}}</div>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="3" class="pb-0 align-end text-right">
        <v-btn
          v-if="!isDishItemInCart"
          fab
          outlined
          class="add-btn"
          @click="addToCart"
        >
          <v-icon dark>
            mdi-plus
          </v-icon>
        </v-btn>

        <v-btn
          v-else
          fab
          depressed
          class="quantity-btn"
          @click="addToCart"
        >
          {{getDishQuantity}}
        </v-btn>
      </v-col>

      <v-col cols="12" class="py-0">
        <div>
          <v-chip
            v-for="(item,index) in dishItem.tags"
            :key="'label-'+index"
            class="title-15 dish-tag"
            :color="item.color"
          >
            {{getTagName(item)}}
          </v-chip>
        </div>
      </v-col>
    </v-row>

    <v-row v-if="hasModifiers || hasIngredients">
      <v-col cols="12" :class="{'d-none': !isDishItemInCart}">
        <v-btn
          depressed
          block
          class="configuration-btn"
          @click="addToCart"
        >
          Add another with different configuration
          <v-spacer></v-spacer>
          <v-icon size="13" class="right-btn">{{right_arrow_btn}}</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
  import { mapGetters, mapActions } from "vuex";
  
  export default {
    name: 'DishItemTemplate',
    props: ["dishItem", "dishIndex"],
    computed: {
      ...mapGetters([
        "cart"
      ]),
      isDishItemInCart() {
        let result = false;
        let matchDish = this.cart.find(el => el.id === this.dishItem.id);
        if (matchDish) {
          result = true;
        }
        return result;
      },
      getDishQuantity() {
        let result = 0;
        this.cart.forEach((el) => {
          if(el.id === this.dishItem.id) {
            result += el.quantity;
          }
        });
        return result;
      },
      hasModifiers() {
        return this.dishItem.modifiers.length != 0;
      },
      hasIngredients() {
        return this.dishItem.ingredients.length != 0;
      },
    },
    data: () => ({
      heart_white: "$vuetify.icons.heart_white",
      heart_blue: "$vuetify.icons.heart_blue",
      favourite_btn_disabled: "$vuetify.icons.favourite_btn_disabled",
      favourite_btn_enabled: "$vuetify.icons.favourite_btn_enabled",
      right_arrow_btn: "$vuetify.icons.right_arrow_btn",
      star_icon: "$vuetify.icons.star_icon",
      publicPath: process.env.VUE_APP_BASE_URL,
      colors: ['#C4F3FF', '#FF6700', '#FFB891']
    }),
    methods: {
      ...mapActions([
        "setDishDetails",
        "resetDishDetails"
      ]),
      addToCart() {
        let dishItem = this.cart.find(el => el.id === this.dishItem.id);
        let update = true;

        if (this.hasModifiers || this.hasIngredients || dishItem == undefined) {
          dishItem = JSON.parse(JSON.stringify(this.dishItem));
          update = false;
        }
        this.resetDishDetails().then(() => {
          this.setDishDetails(dishItem).then(() => {
            this.$emit('add-to-cart', update);
          });
        });
      },
      getTagName(item) {
        let result = '';
        if (item.abbreviation != null) {
          result = item.abbreviation;
        } else {
          result = item.name.toLowerCase();
        }
        return result;
      }
    }
  };
</script>

<style type="text/css">
  .dish-img-container {
    border-radius: 30px;
    padding-top: 0;
    padding-bottom: 4px;
    position: relative;
  }

  .dish-img {
    text-align: right;
    border: 1px solid transparent;
    border-radius: 30px;
  }

  .dish-img:active:not(:focus-within) {
    border: 1px solid #001254;
  }

  .fresh-sticker {
    position: absolute;
    left: 24px;
    bottom: 24px;
  }

  #my-app .favourite-btn,
  #my-app .favourite-btn-large {
    position: absolute;
    top: 17px;
    right: 26px;
  }

  #my-app .favourite-btn-large {
    padding: 8px 10px;
    color: #001254;
    background: #ffffff;
    border-radius: 17px;
  }

  #my-app .favourite-btn .v-icon,
  #my-app .favourite-btn .v-icon .v-icon__component {
    width: 44px;
    height: 34px;
  }

  .dish-price {
    margin-left: 60px;
  }

  #my-app .add-btn {
    border: 1px solid #5BE789;
    color: #001254;
    font-weight: 200;
    font-size: 36px;
    line-height: 28px;
  }

  #my-app .quantity-btn {
    background-color: #5BE789;
    color: #001254;
    font-weight: 200;
    font-size: 36px;
    text-indent: 0%;
  }

  #my-app .quantity-btn .v-btn__content {
    line-height: 1;
  }

  #my-app .my-chip {
    color: #001254;
    border-radius: 50%;
    padding: 2px 5px;
    height: 24px;
  }

  .my-chip:not(:last-child) {
    margin-right: 6px;
  }

  #my-app .configuration-btn {
    height: 27px;
    padding: 17px 25px;
    background: #001254;
    border-radius: 44px;
    font-weight: 400;
    font-size: 12px;
    line-height: 13px;
    color: #FFFFFF;
  }

  #my-app .dish-container {
    margin-top: 30px;
  }

  .right-btn svg path {
    fill: #ffffff;
  }

  .max-three-lines {
    display: -webkit-box;
    height: 39px;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>