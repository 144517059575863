<template>
  <v-list>
    <v-list-item
      @click="$emit('change-address')"
    >
      <v-list-item-icon class="mr-4">
        <v-icon>
          {{location_icon}}
        </v-icon>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title
          class="title-12 text-left"
        >
          <span v-if="isAddressSelected">
            {{selectedAddress.name}}
          </span>
          <span v-else>Select Address</span>
        </v-list-item-title>
      </v-list-item-content>

      <v-list-item-action
        class="small-text"
      >
        Change
      </v-list-item-action>
    </v-list-item>

    <v-divider class="my-divider"></v-divider>

    <v-list-item
      @click="$emit('select-later-time')"
    >
      <v-list-item-icon class="mr-4">
        <v-icon>
          {{getItemIcon(selectedOrderReceiveTime.delivery.icon)}}
        </v-icon>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title
          class="title-12 text-left"
        >
          <div v-if="showLaterDetails">
            {{selectedLaterDate.title}} at {{deliveryTimeList[selectedDeliveryLaterTime]}}
          </div>
          <div v-else>{{selectedOrderReceiveTime.delivery.title}}</div>
        </v-list-item-title>
      </v-list-item-content>

      <v-list-item-action
        class="small-text"
      >
        Change
      </v-list-item-action>
    </v-list-item>
  </v-list>
</template>

<script>
  import { mapGetters, mapActions } from "vuex";

  export default {
    name: 'DeliveryOptions',
    computed: {
      ...mapGetters([
        "selectedAddress",
        "selectedOrderReceiveTime",
        "orderReceiveMethod",
        "selectedLaterDate",
        "selectedDeliveryLaterTime",
        "deliveryTimeList"
      ]),
      isAddressSelected() {
        return this.selectedAddress != undefined && this.selectedAddress != null;
      },
      isSelectedLaterDateEmpty() {
        return Object.keys(this.selectedLaterDate).length === 0;
      },
      showLaterDetails() {
        let isLaterSelected = this.selectedOrderReceiveTime[this.orderReceiveMethod].id == 2;
        return isLaterSelected && !this.isSelectedLaterDateEmpty;
      }
    },
    data: () => ({
      location_icon: "$vuetify.icons.location_icon",
      time_icon: "$vuetify.icons.time_icon",
      now_icon: "$vuetify.icons.now_icon",
      later_icon: "$vuetify.icons.later_icon",
    }),
    methods: {
      getItemIcon(value) {
        return this[value];
      }
    }
  };
</script>
