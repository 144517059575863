<template>
  <v-bottom-sheet
    v-model="show"
    content-class="my-bottom-sheet"
    inset
  >
    <v-sheet
      class="my-sheet"
    >
      <div class="pa-3 text-right">
        <v-btn
          icon
          @click="closeOverlay"
          v-touch="{
            down: () => closeOverlay()
          }"
        >
          <v-icon>
            {{close_blue_btn}}
          </v-icon>
        </v-btn>
      </div>

      <v-container class="full-height">
        <v-row>
          <v-col cols="12" class="text-right">
            <v-btn
              text
              color="#001254"
              class="small-text"
              @click="clearAll"
            >
              Clear all
            </v-btn>
          </v-col>
        </v-row>

        <div id="filters-section" class="overflow-content mt-4">
          <v-text-field
            v-model="keywordString"
            label="Craving for ..."
            solo
            flat
            :append-icon="search_icon"
            class="search-textfield"
            hide-details
          ></v-text-field>

          <v-row class="mt-7 mb-5">
            <v-col cols="12" class="py-0 text-left">
              <div class="title-13">
                Quick Select
              </div>
            </v-col>
          </v-row>

          <v-chip-group
            v-model="selectedFiltersList"
            column
            multiple
            active-class="filter-chip-active"
          >
            <v-chip
              v-for="(item, index) in filters"
              :key="'filter-' + index"
              :value="item"
              class="title-10 filter-chip"
            >
              {{getTagName(item)}}
            </v-chip>
          </v-chip-group>

          <v-row class="mt-7 mb-5">
            <v-col cols="12" class="py-0 text-left">
              <div class="title-13">
                Dietary Preferences
              </div>
            </v-col>
          </v-row>

          <v-list flat>
            <v-list-item-group
              v-model="selectedPreferencesList"
              multiple
            >
              <v-list-item
                v-for="(item, i) in dietaryPreferences"
                :key="`item-${i}`"
                :value="item"
                class="dietary-list-item"
              >
                <template v-slot:default="{ active }">
                  <v-list-item-content class="text-left py-2">
                    <v-list-item-title
                      v-text="item.name"
                      class="title-13"
                    ></v-list-item-title>
                  </v-list-item-content>

                  <v-list-item-action class="my-0">
                    <v-checkbox
                      :input-value="active"
                      color="deep-purple accent-4"
                      :off-icon="checkbox_off"
                      :on-icon="checkbox_on"
                      class="small-checkbox"
                      :ripple="false"
                    ></v-checkbox>
                  </v-list-item-action>
                </template>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </div>
      </v-container>

      <v-btn
        depressed
        class="select-btn"
        :class="{'active-select-btn': !isSelectedFiltersEmpty}"
        @click="selectFilters"
        :disabled="isSelectedButtonInactive"
      >
        Select
        <span
          v-if="!isSelectedFiltersEmpty"
          class='ml-1'
        >
          ({{selectedFilters.length}})
        </span>
      </v-btn>
    </v-sheet>
  </v-bottom-sheet>
</template>

<script>
  import { mapGetters, mapActions } from "vuex";

  export default {
    name: 'SelectFilters',
    props: ["visible"],
    computed: {
      ...mapGetters([
        "filters",
        "dietaryPreferences",
        "selectedFilters",
        "selectedPreferences",
        "keyword",
        "allDishes"
      ]),
      show: {
        get () {
          return this.visible
        },
        set (value) {
           if (!value) {
            this.$emit('close');
          }
        }
      },
      selectedFiltersList: {
        get () {
          return this.$store.state.filters.selectedFilters;
        },
        set(value) {
          this.$store.commit('setSelectedFilters', value);
        }
      },
      selectedPreferencesList: {
        get () {
          return this.$store.state.filters.selectedPreferences;
        },
        set(value) {
          this.$store.commit('setSelectedPreferences', value);
        }
      },
      keywordString: {
        get() {
          return this.$store.state.filters.keyword;
        },
        set(value) {
          this.$store.commit('setKeyword', value);
        }
      },
      isSelectedFiltersEmpty() {
        return this.selectedFilters.length === 0;
      },
      isSelectedPreferencesEmpty() {
        return this.selectedPreferences.length === 0;
      },
      isKeywordEmpty() {
        return this.keyword === '';
      },
      isSelectedButtonInactive() {
        return this.isSelectedFiltersEmpty && this.isSelectedPreferencesEmpty && this.isKeywordEmpty;
      }
    },
    data: () => ({
      close_blue_btn: "$vuetify.icons.close_blue_btn",
      search_icon: "$vuetify.icons.search_icon",
      checkbox_off: "$vuetify.icons.checkbox_off",
      checkbox_on: "$vuetify.icons.checkbox_on"
    }),
    methods: {
      ...mapActions([
        "filterDishes", 
        "resetFilters",
        "setDishes"
      ]),
      closeOverlay() {
        let element = document.getElementById("filters-section");
        element.scrollTop = 0;
        this.$emit('close');
      },
      selectFilters() {
        let element = document.getElementById("filters-section");
        this.filterDishes(this.allDishes).then((res) => {
          element.scrollTop = 0;
          this.$emit('select-filters', res);
        });
      },
      getTagName(item) {
        let result = '';
        if (item.abbreviation != null) {
          result = item.abbreviation;
        } else {
          result = item.name.toLowerCase();
        }
        return result;
      },
      clearAll() {
        this.resetFilters().then(() => {
          this.setDishes(this.allDishes);
        });
      }
    }
  };
</script>

<style type="text/css">
  #filters-section.overflow-content {
    padding: 0 20px 180px;
  }

  #my-app .search-textfield .v-input__control {
    min-height: unset;
  }

  #my-app .search-textfield .v-input__control .v-input__slot {
    border-bottom: 2px solid #001254;
    border-radius: 0;
    padding-left: 0;
  }

  #my-app .search-textfield.v-text-field input {
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    color: #001254;
  }

  #my-app .search-textfield.v-text-field .v-text-field__slot label {
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    color: #BDBDBD;
  }

  #my-app .filter-chip {
    border: 1px solid #5BE789;
    border-radius: 21px;
    color: #001254;
    height: 42px;
    margin: 6px 12px 6px 0;
  }

  #my-app .filter-chip.v-chip:not(.v-chip--active) {
    background: #ffffff;
  }

  #my-app .filter-chip-active {
    background: #5BE789;
  }

  .dietary-list-item {
    min-height: unset;
    padding-left: 0;
  }

  #my-app .select-btn.v-btn--disabled {
    pointer-events: unset;
  }
</style>