<template>
  <div id="add-tip-container">
    <v-row v-if="isAddingTip" class="mt-1">
      <v-col cols="12" class="pb-1">
          <v-text-field
              v-model="customTipAmount"
              type="number"
              placeholder="Add tip amount"
              class="add-tip-textfield"
              color="#001254"
              outlined
              rounded
              height="54"
              hide-details
          >
            {{customTipAmount}}
            <template v-slot:append>
              <v-btn
                filled
                depressed
                height="53"
                color="#001254"
                class="add-custom-tip-btn"
                :disabled="isCustomTipAmountEmpty"
                @click="addCustomTipAction(customTipAmount)"
              >
                add
              </v-btn>
            </template>
          </v-text-field>
      </v-col>
      <v-col cols="12" class="py-0 pl-7 text-left">
        <v-btn
          text
          color="#001254"
          class="small-text"
          @click="isAddingTip = false"
        >
          &lt; back
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="12" class="pb-1">
        <v-chip-group
          v-model="selected_tip"
          mandatory
          column
          class="tip-chip-group"
        >
          <v-chip
            v-for="(item, index) in tips"
            :key="'tip-'+index"
            class="title-6 tip-chip"
            :class="{'d-none': index==0}"
            active-class="active-chip"
            @click="editCustomTip(index)"
          >
            {{ item }}
          </v-chip>
        </v-chip-group>
      </v-col>
      <v-col cols="12" class="text-center pa-0">
        <v-btn
          text
          color="#001254"
          class="title-14 no-tip-btn"
          @click="resetTipAction"
        >
          No tip :(
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>
  
<script>
    import { mapGetters, mapActions } from "vuex";
  
    export default {
      name: 'AddTip',
      computed: {
        ...mapGetters([
            "tips",
            "selectedTip",
            "orderTotal"
        ]),
        selected_tip: {
          get() {
            return this.$store.state.orders.selectedTip;
          },
          set(value) {
            this.$store.commit("setSelectedTip", value);
          }
        },
        isCustomTipAmountEmpty() {
          return this.customTipAmount === '';
        }
      },
      data: () => ({
        customTipAmount: '',
        isAddingTip: false
      }),
      methods: {
        ...mapActions([
          "addCustomTip",
          "resetTip"
        ]),
        addCustomTipAction(value) {
          if (value != undefined && value != '') {
            this.addCustomTip(value).then(() => {
              this.isAddingTip = false;
            })
          } 
        },
        editCustomTip(index) {
          if (index == 4) {
            this.isAddingTip = true;
          }
        },
        resetTipAction() {
          this.customTipAmount = '';
          this.isAddingTip = false;
          this.resetTip();
        }
      }
    };
  </script>
  
  <style type="text/css">
    .add-tip-textfield {
      border-radius: 83px;
    }
  
    #add-tip-container .add-tip-textfield.v-input__slot {
      padding: 0 20px;
    }
  
    #add-tip-container .add-tip-textfield.v-text-field--outlined fieldset {
      border: 1px solid #001254;
    }
  
    #add-tip-container .add-tip-textfield .v-input__slot {
      padding-right: 0;
      min-height: unset;
    }
  
    #add-tip-container .add-tip-textfield .v-input__slot input {
      font-weight: 400;
      font-size: 16px;
      line-height: 120%;
      color: #001254;
    }
  
    #add-tip-container .add-tip-textfield .v-input__append-inner {
      margin-top: 0;
    }

    #my-app .tip-chip {
      background: #F2F2F2;
      border-radius: 83px;
      color: #001254;
      height: 54px;
      padding: 0 14px;
      margin: 0 4px 0 0;
    }
  
    #my-app .tip-chip-group .v-slide-group__content {
      justify-content: space-around;
    }
  
    #my-app .active-chip {
      background: #001254;
    }
  
    #my-app .active-chip .v-chip__content {
      color: #ffffff;
    }

    .no-tip-btn {
      border-radius: 18px;
    }
  </style>
  