<template>
  <v-container
    id="main-feed"
    :class="{'extra-bottom-padding': !isOrderCartEmpty}"
  >

    <TopBar
      @show-select-address="showSelectAddress = true"
      @show-nav-drawer="showNavDrawer = true"
    />

    <div class="my-page-content">
      <OurCooksFiltersBar
        :showChefList="showChefList"
        :showSelectFilters="showSelectFilters"
        @open-chef-list="openChefList"
        @open-filters-overlay="showSelectFilters = true"
      />

      <v-row>
        <DishItemTemplate
          v-for="(item, index) in dishList"
          :key="'dish-'+index"
          :id="item.id"
          :dishItem="item"
          :dishIndex="index"
          @go-to-chef-profile="goToChefProfile"
          @toggleFavourite="toggleFavouriteAction"
          @add-to-cart="addToCart"
        />
      </v-row>

      <div
        v-if="!isOrderCartEmpty"
        class="main-btn-container"
      >
        <v-btn
          depressed
          class="order-now-btn"
          @click="goToBasketReview"
        >
          <div class="d-flex justify-center align-baseline">
            <span>Order now</span>
            <span class="ml-1 small-text">
              {{getTotalCartItems}}
            </span>
          </div>
          <v-spacer></v-spacer>
          <span>{{getTotal.toFixed(2)}} &euro;</span>
        </v-btn>
      </div>
    </div>

    <SelectAddressOverlay
      :visible="showSelectAddress"
      @close="showSelectAddress = false"
    />

    <ChefList
      :visible="showChefList"
      @close="showChefList = false"
      @select-chefs="updateDishList"
    />

    <SelectFiltersOverlay
      :visible="showSelectFilters"
      @close="showSelectFilters = false"
      @select-filters="selectFilters"
    />

    <AddToCartOverlay
      :visible="showAddToCart"
      :update="updateCartItem"
      @close="showAddToCart = false"
    />

    <!-- Reviews to be added in another version -->
    <!-- <ReviewOverlay
      :visible="showReviewOverlay"
      @close="showReviewOverlay = false"
    /> -->

    <SignInModal
      :visible="showSignInModal"
      @close="showSignInModal = false"
    />

    <NavigationDrawer
      :visible="showNavDrawer"
      @close="showNavDrawer = false"
      @open-sign-in-modal="openSignInModal"
    />
  </v-container>
</template>

<script>
  import { mapGetters, mapActions } from "vuex";
  import TopBar from '@/components/Navigation/TopBar';
  import OurCooksFiltersBar from '@/components/MainFeed/OurCooksFiltersBar';
  import DishItemTemplate from '@/components/MainFeed/DishItemTemplate';
  import ChefList from '@/components/MainFeed/ChefList';
  import NavigationDrawer from '@/components/Navigation/NavigationDrawer';
  import AddToCartOverlay from '@/components/MainFeed/AddToCartOverlay';
  import SelectAddressOverlay from '@/components/MainFeed/SelectAddressOverlay';
  import SelectFiltersOverlay from '@/components/MainFeed/SelectFiltersOverlay';
  import ReviewOverlay from '@/components/MainFeed/ReviewOverlay';
  import SignInModal from '@/components/Auth/SignInModal.vue';

  export default {
    name: 'MainFeed',
    metaInfo: {
      title: 'WeCook Menu Ημέρας',
      titleTemplate: '%s',
      htmlAttrs: {
        lang: 'en',
        amp: true
      }
    },
    components: {
      TopBar,
      OurCooksFiltersBar,
      DishItemTemplate,
      ChefList,
      NavigationDrawer,
      AddToCartOverlay,
      SelectAddressOverlay,
      SelectFiltersOverlay,
      ReviewOverlay,
      SignInModal
    },
    computed: {
      ...mapGetters([
        "isUserLoggedIn",
        "userProfile",
        "allDishes",
        "dishList",
        "dishDetails",
        "cart",
        "selectedChefs",
        "myOrders",
        "selectedFilters",
        "selectedPreferences"
      ]),
      getTotal() {
        let total = 0;
        for (let k = 0; k < this.cart.length; k++) {
          let item = this.cart[k];
          total += (item.quantity * item.totalItemPrice);
        }
        total = Math.round(total * 100) / 100;
        return total;
      },
      getTotalCartItems() {
        let result = '';
        let totalQuantity = 0;
        this.cart.forEach((el) => {
          totalQuantity += el.quantity;
        });
        if (totalQuantity === 1) {
          result = totalQuantity + ' item';
        } else if (totalQuantity > 1) {
          result = totalQuantity + ' items';
        }
        return result;
      },
      isOrderCartEmpty() {
        return this.cart.length === 0;
      }
    },
    data: () => ({
      showSignInModal: false,
      showSelectAddress: false,
      showChefList: false,
      showNavDrawer: false,
      showSelectFilters: false,
      showAddToCart: false,
      showReviewOverlay: false,
      updateCartItem: false,
      campaignDishId: null
    }),
    mounted() {
      let queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      let campaign = urlParams.get('campaign');
      let dishId = urlParams.get('dishId');

      if (campaign != null && dishId != null) {
        this.campaignDishId = parseInt(dishId);
        setTimeout(() => {
          this.openDishDetailsForCampaign();
        }, 2000);
      }
    },
    methods: {
      ...mapActions([
        "toggleFavourite",
        "toggleFavouriteDish",
        "getChefDetails",
        "setDishes",
        "setOrderTotal",
        "resetDishDetails",
        "setDishDetails"
      ]),
      goToChefProfile(chefId) {
        this.getChefDetails(chefId).then(() => {
          this.$router.push({name: 'chef_profile'});
        });
      },
      openChefList() {
        this.showChefList = true;
      },
      goToBasketReview() {
        let orderTotal = this.getTotal;
        this.setOrderTotal(orderTotal).then(() => {
          this.$router.push({name: 'basket_review'});
        });
      },
      openSignInModal() {
        this.showNavDrawer = false;
        this.showSignInModal = true;
      },
      updateDishList() {
        this.setDishes(this.allDishes).then(() => {
          if (this.selectedChefs.length != 0) {
            let result = this.dishList.filter((item) => {
              return this.selectedChefs.indexOf(item.chefId) != -1;
            });
            this.setDishes(result).then(() => {
              this.showChefList = false;
            });
          } else {
            this.showChefList = false;
          }
        });
      },
      selectFilters(filteredDishList) {
        this.setDishes(this.allDishes).then(() => {
          this.setDishes(filteredDishList).then(() => {
            this.showSelectFilters = false;
          });
        });
      },
      toggleFavouriteAction(dishItem) {
        let enabled = dishItem.favourite ? false : true;
        if (this.isUserLoggedIn) {
          let payload = {
            customerId: this.userProfile.id,
            data: {
              email: this.userProfile.email,
              itemIds: [dishItem.id],
              enabled: enabled
            }
          }
          this.toggleFavouriteDish(payload).then(() => {
            this.toggleFavourite({dishId: dishItem.id, enabled: enabled});
          });
        } else {
          this.toggleFavourite({dishId: dishItem.id, enabled: enabled});
        }
      },
      addToCart(update) {
        this.updateCartItem = update;

        let dishTags = this.dishDetails.tags.map((el) => {
          return el.abbreviation != null ? el.abbreviation : el.name.toLowerCase();
        });

        this.$gtag.event('plate_view', {
          'plate_title': this.dishDetails.title,
          'plate_price': this.dishDetails.price,
          'plate_chef': this.dishDetails.chefName,
          'tags_values': dishTags,
          'currency': 'EUR'
        });

        fbq('track', 'ViewContent', {
          content_name: this.dishDetails.title,
          content_category: dishTags[0],
          content_ids: [this.dishDetails.id],
          content_type: 'product',
          value: this.dishDetails.price,
          currency: 'EUR',
          content_chef: this.dishDetails.chefName,
          content_tags: dishTags.join(',')
        }); 

        this.showAddToCart = true;
      },
      openDishDetailsForCampaign() {
        let dishItemInCart = this.cart.find(el => el.id === this.campaignDishId);
        let dishItem = this.allDishes.find(el => el.id === this.campaignDishId);
        let update = true;
        let dishElement = document.getElementById(this.campaignDishId);

        if (dishItem != undefined) {
          let hasModifiers = dishItem.modifiers.length != 0;
          let hasIngredients = dishItem.ingredients.length != 0;

          if (hasModifiers || hasIngredients || dishItemInCart == undefined) {
            dishItemInCart = JSON.parse(JSON.stringify(dishItem));
            update = false;
          }
          this.resetDishDetails().then(() => {
            if (dishItemInCart != undefined) {
              this.setDishDetails(dishItemInCart).then(() => {
                if (dishElement != null) {
                  dishElement.scrollIntoView({behavior: "smooth"});
                }
                this.addToCart(update);
              });
            }
          });
        }
      }
    }
  };
</script>

<style type="text/css">
  #main-feed {
    padding: 20px 20px 30px;
  }

  #main-feed .my-page-content {
    margin-top: 70px;
  }

  #main-feed.extra-bottom-padding {
    padding-bottom: 120px;
  }
</style>