<template>
  <div id="review-first-step">
    <div class="pa-3 text-right">
      <v-btn
        icon
        width="62"
        class="close-btn"
        @click="$emit('close-overlay')"
      >
        <v-icon>
          {{close_blue_btn}}
        </v-icon>
      </v-btn>
    </div>

    <div class="overflow-content">
      <v-row class="mt-7 mb-5">
        <v-col cols="12">
          <div class="title-5">
            How was your order?
          </div>
          <div class="title-14 mt-2">
            Tell how did you like your order?
          </div>
        </v-col>
      </v-row>

      <v-rating
        v-model="orderRating"
        :empty-icon="empty_star_icon"
        :full-icon="full_star_icon"
        length="5"
        size="48"
      ></v-rating>

      <v-divider class="my-divider my-8"></v-divider>

      <v-row class="mt-8">
        <v-col cols="12">
          <div class="title-5">
            Do you want to tip the chefs?
          </div>
          <div class="title-14 mt-2">
            Add a tip that we will distribute equally to our chefs. You always have the option to customise your tip per chef.
          </div>
        </v-col>
      </v-row>

      <AddTip class="mt-4"/>

      <div class="submit-btn-container">
        <v-btn
          block
          depressed
          class="title-12 confirm-btn"
          :disabled="isEmptyRating"
          @click="$emit('continue', 1)"
        >
          Submit
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from "vuex";
  import AddTip from '@/components/Checkout/AddTip';

  export default {
    name: 'ReviewFirstStep',
    components: {
      AddTip
    },
    computed: {
      ...mapGetters([
      ]),
      orderRating: {
        get() {
          return this.$store.state.reviews.rating;
        },
        set(value) {
          this.$store.dispatch('setRating', value);
        }
      },
      isEmptyRating() {
        return this.orderRating === 0;
      }
    },
    data: () => ({
      close_blue_btn: "$vuetify.icons.close_blue_btn",
      empty_star_icon: "$vuetify.icons.empty_star_icon",
      full_star_icon: "$vuetify.icons.full_star_icon",
      tips: ['0.50', '1.00', '2.00', 'other']
    }),
    methods: {
      ...mapActions([

      ])
    }
  };
</script>

<style type="text/css">
  #review-first-step {
    height: 100%;
  }

  #review-first-step .confirm-btn {
    margin-top: 0;
    
  }
</style>