<template>
  <v-bottom-sheet
    v-model="show"
    scrollable
    content-class="my-bottom-sheet"
    inset
  >
    <v-sheet
      class="text-center"
      id="add-to-cart-sheet"
    >
      <div class="text-right">
        <v-btn
          icon
          width="62"
          class="close-btn"
          @click="closeOverlay"
          v-touch="{
            down: () => closeOverlay()
          }"
        >
          <v-icon>
            {{close_blue_btn}}
          </v-icon>
        </v-btn>
      </div>

      <div
        id="add-to-cart-overlay"
        class="my-bottom-sheet-content scroll-section"
      >
        <div class="dish-image-container">
          <v-img
            height="280"
            :src="publicPath+dishDetails.dishImage"
            class="dish-image"
          ></v-img>
        </div>

        <v-row class="mt-5 text-left">
          <v-col cols="12">
            <div class="title-7">{{dishDetails.title}}</div>
          </v-col>
          <v-col cols="12" class="d-flex align-center pt-0 pb-2">
            <v-avatar size="25">
              <v-img
                alt="user"
                :src="publicPath + dishDetails.chefAvatar"
              ></v-img>
            </v-avatar>
            <div class="title-14 ml-2">
              {{dishDetails.chefName}}
            </div>
          </v-col>

          <v-col cols="12" class="pt-0">
            <div class="description">{{dishDetails.description}}</div>
          </v-col>

          <v-col cols="12" class="pt-2 pb-0">
            <div>
              <v-chip
                v-for="(item,index) in dishDetails.tags"
                :key="'label-'+index"
                class="title-15 dish-tag"
                :color="item.color"
              >
                {{getTagName(item)}}
              </v-chip>
            </div>
          </v-col>
        </v-row>

        <v-divider class="my-divider my-6"></v-divider>

        <div v-if="hasIngredients">
          <v-row class="mb-1 text-left">
            <v-col cols="12" class="d-flex align-center pb-0">
              <div class="title-13">Pick your ingredient</div>
              <v-btn
                class="small-text required-tag"
                height="14"
                color="#001254"
                depressed
              >
                required
              </v-btn>
            </v-col>
            <v-col cols="12" class="pt-1">
              <div class="small-text required-text">Choose up to 1</div>
            </v-col>
          </v-row>

          <v-list flat class="pa-0">
            <v-list-item-group
              v-model="selected_ingredient"
              mandatory
            >
              <v-list-item
                v-for="(item, i) in dishDetails.ingredients"
                :key="`ingredient-${i}`"
                class="ingredients-list-item"
              >
                <template v-slot:default="{ active }">
                  <v-list-item-content class="text-left">
                    <v-list-item-title class="title-13">
                      <div>
                        {{item.content.en.title}}
                      </div>
                    </v-list-item-title>
                  </v-list-item-content>

                  <v-list-item-action class="my-0 checkbox-container">
                    <div
                      v-if="item.price != ''"
                      class="small-text mr-4"
                    >
                      +{{(item.price / 100)}} &euro;
                    </div>
                    <v-checkbox
                      :input-value="active"
                      color="deep-purple accent-4"
                      :off-icon="radio_btn_off"
                      :on-icon="radio_btn_on"
                      class="small-checkbox"
                      readonly
                      :ripple="false"
                    ></v-checkbox>
                  </v-list-item-action>
                </template>
              </v-list-item>
            </v-list-item-group>
          </v-list>

          <v-divider class="my-divider my-6"></v-divider>
        </div>

        <div v-if="hasModifiers">
          <v-row class="mb-1 text-left">
            <v-col cols="12">
              <div class="title-13">Choose add-ons</div>
            </v-col>
          </v-row>

          <v-list flat class="pa-0">
            <v-list-item-group
              v-model="selected_modifiers"
              multiple
            >
              <v-list-item
                v-for="(item, i) in dishDetails.modifiers"
                :key="`modifier-${i}`"
                class="ingredients-list-item"
              >
                <template v-slot:default="{ active }">
                  <v-list-item-content class="text-left">
                    <v-list-item-title class="title-13">
                      <div>
                        {{item.content.en.title}}
                      </div>
                    </v-list-item-title>
                  </v-list-item-content>

                  <v-list-item-action class="my-0 checkbox-container">
                    <div
                      v-if="item.price != ''"
                      class="small-text mr-4"
                    >
                      +{{(item.price / 100)}} &euro;
                    </div>
                    <v-checkbox
                      :input-value="active"
                      color="deep-purple accent-4"
                      :off-icon="checkbox_off"
                      :on-icon="checkbox_on"
                      class="small-checkbox"
                      :ripple="false"
                    ></v-checkbox>
                  </v-list-item-action>
                </template>
              </v-list-item>
            </v-list-item-group>
          </v-list>

          <v-divider class="my-divider my-6"></v-divider>
        </div>

        <v-row>
          <v-col cols="6" class="text-left">
            <div class="title-13">Item Price</div>
          </v-col>
          <v-col cols="6" class="text-right">
            <div class="title-13 pr-1">{{getTotal.toFixed(2)}} &euro;</div>
          </v-col>
        </v-row>
      </div>

      <div class="cart-buttons-container">
        <v-row>
          <v-col cols="5" class="pr-0">
            <div class="quantity-btn-container">
              <div>
                <v-btn
                  icon
                  @click="decreaseQuantity"
                >
                  <v-icon>{{minus_btn}}</v-icon>
                </v-btn>
              </div>
              <div class="title-5 mx-2">{{dishDetails.quantity}}</div>
              <div>
                <v-btn
                  icon
                  @click="increaseQuantity"
                >
                  <v-icon>{{plus_btn}}</v-icon>
                </v-btn>
              </div>
            </div>
          </v-col>
          
          <v-col cols v-if="update">
            <v-btn
              depressed
              block
              height="54"
              class="add-to-cart-btn"
              @click="updateCartItem"
            >
              Update
            </v-btn>
          </v-col>

          <v-col cols v-else>
            <v-btn
              depressed
              block
              height="54"
              class="add-to-cart-btn"
              @click="addToCartAction"
            >
              Add to Cart
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-sheet>
  </v-bottom-sheet>
</template>

<script>
  import { mapGetters, mapActions } from "vuex";

  export default {
    name: 'AddToCartOverlay',
    props: ["visible", "update"],
    computed: {
      ...mapGetters([
        "dishDetails"
      ]),
      show: {
        get () {
          return this.visible
        },
        set (value) {
           if (!value) {
            this.$emit('close');
          }
        }
      },
      getTotal() {
        let extra = 0;
        let total = 0;

        this.dishDetails.selectedModifiers.forEach((el) => {
          let extraPrice = this.dishDetails.modifiers[el].price;
          if (extraPrice != '' && extraPrice != null && extraPrice != undefined) {
            extraPrice = extraPrice / 100;
            extra += parseFloat(extraPrice, 2);
          }
        });

        if (this.dishDetails.ingredients.length != 0) {
          let ingredientPrice = this.dishDetails.ingredients[this.dishDetails.selectedIngredient].price;
          if (ingredientPrice != '' && ingredientPrice != null && ingredientPrice != undefined) {
            ingredientPrice = ingredientPrice / 100;
            extra += parseFloat(ingredientPrice, 2);
          }
        }

        total = parseFloat(this.dishDetails.price, 2) + extra;

        let payload = {
          prop: 'totalItemPrice',
          value: total
        }
        this.$store.commit('updateDishDetails', payload);

        return total;
      },
      selected_modifiers: {
        get() {
          return this.dishDetails.selectedModifiers;
        },
        set(value) {
          this.$store.commit('setSelectedModifiers', value);
        }
      },
      selected_ingredient: {
        get() {
          return this.dishDetails.selectedIngredient;
        },
        set(value) {
          this.$store.commit('setSelectedIngredient', value);
        }
      },
      hasModifiers() {
        return this.dishDetails.modifiers.length != 0;
      },
      hasIngredients() {
        return this.dishDetails.ingredients.length != 0;
      }
    },
    data: () => ({
      close_blue_btn: "$vuetify.icons.close_blue_btn",
      checkbox_off: "$vuetify.icons.checkbox_off",
      checkbox_on: "$vuetify.icons.checkbox_on",
      radio_btn_off: "$vuetify.icons.radio_btn_off",
      radio_btn_on: "$vuetify.icons.radio_btn_on",
      minus_btn: "$vuetify.icons.minus_btn",
      plus_btn: "$vuetify.icons.plus_btn",
      publicPath: process.env.VUE_APP_BASE_URL
    }),
    methods: {
      ...mapActions([
        "addToCart",
        "updateDishDetails"
      ]),
      getTagName(item) {
        let result = '';
        if (item.abbreviation != null) {
          result = item.abbreviation;
        } else {
          result = item.name.toLowerCase();
        }
        return result;
      },
      addToCartAction() {
        let orderItem = JSON.parse(JSON.stringify(this.dishDetails));
        orderItem['orderItemId'] = this.generateId(5);

        let item_category = this.dishDetails.tags.length !=0 ? this.dishDetails.tags[0].name.toLowerCase() : '';

        this.$gtag.event('add_to_cart', {
          'currency': 'EUR',
          'value': this.dishDetails.totalItemPrice * this.dishDetails.quantity,
          'items': [
            {
              'item_name': this.dishDetails.title,
              'price': this.dishDetails.price,
              'quantity': this.dishDetails.quantity,
              'item_category': item_category
            }
          ]
        });

        fbq('track', 'AddToCart', {
          content_name: this.dishDetails.title,
          content_type: 'product',
          value: this.dishDetails.totalItemPrice * this.dishDetails.quantity,
          currency: 'EUR',
          contents: {
            id: this.dishDetails.id,
            quantity: this.dishDetails.quantity,
            item_price: this.dishDetails.price
          }
        });

        this.addToCart(orderItem).then(() => {
          this.closeOverlay();
        });
      },
      decreaseQuantity() {
        let quantity = this.dishDetails.quantity;
        if (quantity != 1) {
          quantity -= 1;
          let payload = {
            prop: 'quantity',
            value: quantity
          };
          this.updateDishDetails(payload);
        }
      },
      increaseQuantity() {
        let quantity = this.dishDetails.quantity;
        quantity += 1;

        let payload = {
          prop: 'quantity',
          value: quantity
        };
        this.updateDishDetails(payload);
      },
      generateId(length) {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
      },
      updateCartItem() {
        this.$emit('close');
      },
      closeOverlay() {
        let element = document.getElementById("add-to-cart-overlay");
        element.scrollTop = 0;
        this.$emit('close');
      }
    }
  };
</script>

<style type="text/css" scoped>
  #add-to-cart-sheet {
    position: relative;
  }

  .close-btn {
    z-index: 5;
  }

  #add-to-cart-overlay.my-bottom-sheet-content {
    padding: 0 20px 120px;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
  }

  .dish-image-container {
    height: 280px;
    margin-left: -20px;
    margin-right: -20px;
  }

  .dish-image {
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
  }

  .description {
    font-size: 16px;
    line-height: 120%;
    text-align: left;
  }

  .ingredients-list-item {
    min-height: unset;
    padding-left: 0;
  }

  .ingredients-list-item .v-list-item__content {
    padding: 6px 0;
  }

  .ingredients-list-item .v-list-item__content:last-child {
    padding: 6px 0 0;
  }

  .scroll-section {
    margin-top: -36px;
    height: 100%;
    overflow-y: auto;
    scroll-behavior: smooth;
    overscroll-behavior: none;
  }

  .quantity-btn-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #5BE789;
    border-radius: 78px;
    padding: 9px 15px;
  }

  .cart-buttons-container {
    position: absolute;
    bottom: 20px;
    left: 0;
    width: 100%;
    padding: 0 20px;
  }
  
  .checkbox-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }

  .required-tag {
    color: #ffffff;
    border-radius: 7px;
    margin-left: 10px;
  }

  .required-text {
    opacity: 0.5;
  }
</style>

<style type="text/css">
  #my-app .add-to-cart-btn {
    background: #001254;
    border-radius: 44px;
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    color: #FFFFFF;
  }
</style>