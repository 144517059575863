<template>
  <v-bottom-sheet
    v-model="show"
    content-class="my-bottom-sheet"
    inset
  >
    <v-sheet
      class="my-sheet"
    >
      <div class="pa-3 text-right">
        <v-btn
          icon
          width="62"
          @click="closeOverlay"
          v-touch="{
            down: () => closeOverlay()
          }"
        >
          <v-icon>
            {{close_blue_btn}}
          </v-icon>
        </v-btn>
      </div>

      <v-container class="full-height">
        <v-row class="pa-4">
          <v-col cols="6">
            <div
              class="title-5 black--text text-left"
            >
              Our Chefs
            </div>
          </v-col>
          <v-col cols="6" class="text-right">
            <v-btn
              text
              color="#001254"
              class="small-text"
              @click="clearAll"
            >
              Clear all
            </v-btn>
          </v-col>
        </v-row>

        <div id="chef-list" class="overflow-content">
          <v-list two-line flat class="pb-10 mb-10">
            <v-list-item-group
              v-model="selectedChefsList"
              active-class="active-chef"
              multiple
            >
              <v-list-item
                v-for="(item, index) in chefList"
                :key="index"
                :value="item.id"
                class="chef-list-item"
              >
                <template v-slot:default="{ active }">
                  <v-list-item-avatar class="my-0 mr-3">
                    <v-img :src="publicPath + item.avatar"></v-img>
                  </v-list-item-avatar>

                  <div class="chef-btn">
                    <v-list-item-content class="text-left">
                      <v-list-item-title
                        v-text="item.chefName"
                        class="title-12"
                      >
                      </v-list-item-title>

                      <v-list-item-subtitle class="usually-serves">
                        {{getChefDescription(item)}}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    
                    <!-- Reviews to be added to another version -->
                    <!-- <v-list-item-action class="review-item">
                      <v-icon
                        size="12"
                        class="mr-1"
                      >
                        {{star_icon}}
                      </v-icon>

                      <v-list-item-action-text
                        v-text="item.review"
                        class="chef-review"
                      >
                      </v-list-item-action-text>

                    </v-list-item-action> -->
                  </div>
                </template>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </div>
      </v-container>

      <v-btn
        depressed
        class="select-btn"
        :class="{'active-select-btn': !isSelectedChefsEmpty}"
        @click="selectChefs"
      >
        Select
        <span
          v-if="!isSelectedChefsEmpty"
          class='ml-1'
        >
          ({{selectedChefs.length}})
        </span>
      </v-btn>

    </v-sheet>
  </v-bottom-sheet>
</template>

<script>
  import { mapGetters, mapActions } from "vuex";

  export default {
    name: 'ChefList',
    props: ["visible"],
    computed: {
      ...mapGetters([
        "chefList",
        "selectedChefs",
        "allDishes"
      ]),
      show: {
        get () {
          return this.visible
        },
        set (value) {
           if (!value) {
            this.$emit('close');
          }
        }
      },
      isSelectedChefsEmpty() {
        return this.selectedChefs.length === 0;
      },
      selectedChefsList: {
        get () {
          return this.$store.state.chefs.selectedChefs;
        },
        set(value) {
          this.$store.commit('setSelectedChefs', value);
        }
      },
    },
    data: () => ({
      star_icon: "$vuetify.icons.star_icon",
      close_blue_btn: "$vuetify.icons.close_blue_btn",
      publicPath: process.env.VUE_APP_BASE_URL
    }),
    methods: {
      ...mapActions(["setSelectedChefs", "setDishes"]),
      clearAll() {
        this.setSelectedChefs([]);
        this.setDishes(this.allDishes);
      },
      closeOverlay() {
        let element = document.getElementById("chef-list");
        element.scrollTop = 0;
        this.$emit('close');
      },
      selectChefs() {
        let element = document.getElementById("chef-list");
        element.scrollTop = 0;
        this.$emit('select-chefs');
      },
      getChefDescription(item) {
        let result = '';
        let tagsJoined = this.joinNames(item.tags);
        if (tagsJoined != '') {
          result = 'Usually serves ' + tagsJoined
        }
        return result;
      },
      joinNames(array) {
        let result = '';
        for (let k = 0; k < array.length; k++) {
          let temp = array[k].name.toLowerCase();
          if (array[k].abbreviation != null && array[k].abbreviation != '') {
            temp = array[k].abbreviation;
          }
          result += temp;
          if (k != array.length - 1) {
            result += ', ';
          }
        }
        return result;
      }
    }
  };
</script>

<style type="text/css">
  #my-app .chef-list-item {
    margin-bottom: 12px;
    padding: 0;
    justify-content: center;
  }

  #my-app .review-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 6px;
  }

  .chef-btn {
    border: 1px solid #5BE789;
    border-radius: 60px;
    display: flex;
    flex-wrap: wrap;
    padding: 0 22px;
    width: 85%;
  }
  
  #my-app .chef-description {
    font-weight: 400;
    font-size: 12px;
    line-height: 13px;
    display: flex;
    align-items: center;
    color: #001254;
  }

  #my-app .chef-review {
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    color: #001254;
  }

  #my-app .active-chef .chef-btn {
    background: #5BE789;
  }

  .usually-serves {
    max-width: 400px;
  }
</style>