<template>
  <v-bottom-sheet
    v-model="show"
    content-class="my-bottom-sheet address-bottom-sheet"
    scrollable
    inset
  >
    <v-sheet
      class="text-center full-height"
    >
      <div class="pa-3 text-right">
        <v-btn
          icon
          width="62"
          @click="closeOverlay"
          v-touch="{
            down: () => closeOverlay()
          }"
        >
          <v-icon>
            {{close_blue_btn}}
          </v-icon>
        </v-btn>
      </div>

      <div class="my-bottom-sheet-content full-height">
        <component
          v-bind:is="activeComponent"
          @change-address="activeComponent = 'change-address'"
          @back-to-menu="activeComponent = 'select-address-menu'"
          @add-new-address="openNewAddressForm"
          @open-edit-address-form="openEditAddressForm"
          @select-later-time="activeComponent = 'select-later-time'"
          @confirm-select-address="confirmSelectAddress"
          @create-address="createAddress"
          @edit-address="editAddressAction"
        ></component>
      </div>
    </v-sheet>

    <v-overlay :value="showLoader" z-index=12>
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </v-bottom-sheet>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import SelectAddressMenu from '@/components/SelectAddress/SelectAddressMenu';
  import ChangeAddress from '@/components/SelectAddress/ChangeAddress';
  import AddNewAddress from '@/components/SelectAddress/AddNewAddress';
  import EditAddress from '@/components/SelectAddress/EditAddress';
  import SelectLaterTime from '@/components/SelectAddress/SelectLaterTime';

  export default {
    name: 'SelectAddressOverlay',
    components: {
      'select-address-menu': SelectAddressMenu,
      'change-address': ChangeAddress,
      'add-new-address': AddNewAddress,
      'edit-address': EditAddress,
      'select-later-time': SelectLaterTime
    },
    props: ["visible"],
    computed: {
      ...mapGetters([
        "userProfile",
        "isUserLoggedIn"
      ]),
      show: {
        get () {
          return this.visible
        },
        set (value) {
           if (!value) {
            this.$emit('close');
          }
        }
      }
    },
    data: () => ({
      showLoader: false,
      close_blue_btn: "$vuetify.icons.close_blue_btn",
      location_icon: "$vuetify.icons.location_icon",
      time_icon: "$vuetify.icons.time_icon",
      activeComponent: 'select-address-menu'
    }),
    methods: {
      ...mapActions([
        "setConfirmAddress",
        "setEditAddress",
        "getAddressList",
        "createNewAddress",
        "updateAddress",
        "setNewAddress",
        "addAddressToAddressList",
        "setIsNewAddress"
      ]),
      confirmSelectAddress() {
        this.setConfirmAddress(true).then(() => {
          this.$emit('close');
        })
      },
      closeOverlay() {
        let addressBottomSheet = document.getElementsByClassName('address-bottom-sheet')[0];
        addressBottomSheet.style.height = 'initial';

        this.activeComponent = 'select-address-menu';
        this.$emit('close');
      },
      openEditAddressForm(item) {
        let addressBottomSheet = document.getElementsByClassName('address-bottom-sheet')[0];
        addressBottomSheet.style.height = 'initial';
        let addressObj = JSON.parse(JSON.stringify(item));
        this.setEditAddress(addressObj).then(() => {
          this.activeComponent = 'edit-address';
        });
      },
      openNewAddressForm() {
        let addressBottomSheet = document.getElementsByClassName('address-bottom-sheet')[0];
        addressBottomSheet.style.height = 'initial';
        this.activeComponent = 'add-new-address';
      },
      createAddress(addressObj) {
        this.showLoader = true;
        this.setNewAddress(addressObj);
        let email = this.userProfile.email;

        if (this.isUserLoggedIn) {
          let payload = {
            email: email,
            requestData: [addressObj]
          }
          this.createNewAddress(payload).then(() => {
            this.getAddressList(email).then(() => {
              this.showLoader = false;
              this.activeComponent = 'change-address';
            });
          });
        } else {
          this.setIsNewAddress(true);
          this.addAddressToAddressList(addressObj).then(() => {
            this.showLoader = false;
            this.activeComponent = 'change-address';
          });
        }
      },
      editAddressAction(addressObj) {
        this.showLoader = true;
        let email = this.userProfile.email;

        if (this.isUserLoggedIn) {
          let payload = {
            email: email,
            requestData: [addressObj]
          };

          this.updateAddress(payload).then(() => {
            this.getAddressList(email).then(() => {
              this.showLoader = false;
              this.activeComponent = 'change-address';
            });
          });
        } else {
          this.setNewAddress(addressObj);
          this.showLoader = false;
          this.activeComponent = 'change-address';
        }
      }
    }
  };
</script>

<style type="text/css">
  #my-app .new-address-title {
    text-align: left;
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    color: #001254;
  }
</style>